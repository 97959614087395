/**
 *
 * Notifications constants
 * @author Chad Watson
 *
 *
 */
export const NOTIFICATION_TYPES = {
  DEFAULT: "default",
  WARNING: "warning",
  ERROR: "error",
  ACTIONABLE: "actionable",
  PROGRESS: "progress"
};
