import * as Sentry from "@sentry/browser";
import { APP_ELEMENT } from "config/app";
import App from "containers/App";
import { createThemes } from "containers/App/theme";
import DefaultErrorBoundary from "containers/ErrorBoundary";
import StateRehydrator, { initPersistence } from "containers/StateRehydrator";
import Theme, {
  themeGrayDark,
  themeGrayLight,
  themePrimaryFontFamily,
} from "containers/Theme";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "react-router-redux";
import styled, { createGlobalStyle } from "styled-components/macro";
import LanguageProvider from "./LanguageProvider";

// Eventually remove this when react-redux updates to support the context API and react hooks
import { Provider } from "hooks/useRedux";

// Import the CSS reset, which HtmlWebpackPlugin transfers to the build folder
import "sanitize.css/sanitize.css";

import store, { history } from "./store";

import AppLoading from "components/AppLoading";
import FadeInOut from "components/FadeInOut";
import { SecureComEnvContextProvider } from "contexts/SecureComEnvContext";

if (process.env.NODE_ENV === "development") {
  const originalConsole = console.error;

  console.error = (...args) => {
    if (
      typeof args[0] === "string" &&
      args[0].includes("Warning: Failed prop type")
    ) {
      const [message] = args[0].split("\n");

      console.groupCollapsed(message);
      originalConsole(...args);
      console.groupEnd();
    } else {
      originalConsole(...args);
    }
  };
}

if (process.env.REACT_APP_ENABLE_SENTRY === "true") {
  Sentry.init({
    dsn: "https://de0c8582eccb4f18b9afa8f671920fec@sentry.io/236697",
    release: `virtual-keypad-browser@${
      process.env.REACT_APP_RELEASE_TAG
        ? process.env.REACT_APP_RELEASE_TAG
        : process.env.REACT_APP_VERSION
    }`,
    environment: process.env.REACT_APP_DEPLOYMENT_ENV,
  });
}

export const GlobalStyles = createGlobalStyle`
  body {
    overflow-x: hidden;
    color: ${themeGrayDark};
    background: ${themeGrayLight};
    font-family: ${themePrimaryFontFamily};
    font-weight: 400;
    opacity: 1;
    -webkit-font-smoothing: antialiased;

    &.ReactModal__Body--open {
      overflow: hidden;
      & #root {
      filter: blur(20px);
      opacity: .6;
    }
  }
  }

  #root {
    transition: 0.2s filter, 0.2s opacity;
  }
`;

const ErrorBoundary = styled(DefaultErrorBoundary).attrs({
  suppressClassNameWarning: true,
})`
  height: 100vh;
`;

const persistenceResource = initPersistence(store);

const themes = createThemes(window.location);

ReactDOM.render(
  <FadeInOut>
    <SecureComEnvContextProvider>
      <Provider>
        <ConnectedRouter history={history}>
          <Theme defaultTheme="primary" themes={themes}>
            <>
              <GlobalStyles />
              <Suspense fallback={<AppLoading />}>
                <ErrorBoundary>
                  <LanguageProvider>
                    <StateRehydrator resource={persistenceResource}>
                      <App />
                    </StateRehydrator>
                  </LanguageProvider>
                </ErrorBoundary>
              </Suspense>
            </>
          </Theme>
        </ConnectedRouter>
      </Provider>
    </SecureComEnvContextProvider>
  </FadeInOut>,
  APP_ELEMENT
);
